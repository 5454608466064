import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import FooterTitle from '../../components/common/footer-title'
import PageContainer from '../../components/common/page-container'
import Link from '../../components/common/link'

export default (props) => (
    <Layout title="AWS Cloud Architecture">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
                <Title>AWS Cloud Architecture</Title>
            </TitleContainer>
        </BackgroundContainer>
        <PageContainer>
            <p>Modern applications typically require cloud hosting through providers such as AWS.</p>
            <p>Using cloud hosting can ensure your project scales to deal with demand, allowing new servers to be provisioned automatically. However, running a project on cloud hosting requires two key things:</p>
            <ul>
                <li>Ensuring your application is built for cloud services</li>
                <li>Ensuring the cloud services are setup and configured correctly</li>
            </ul>
            <p>We have extensive experience in building applications for cloud services, and we have the certificates to prove it! Our Technical Director, Michael Peacock, is an AWS Certified Solutions Architect, Developer and Cloud Practitioner!</p>
            <h2>Applications built for the cloud</h2>
            <p>We know what needs to be considered when developing applications to ensure they can take advantage of cloud technologies. Many inexperienced technologists believe that you can just upload an application to AWS and it will take care of itself, however applications need to be built to ensure they can leverage cloud technologies.</p>
            <p>We specialise in robust, stable applications which run well in cloud hosting environments.</p>

            <h2>Cloud architecture</h2>
            <p>So that your application can scale, you need to ensure the right cloud architecture is in place, we can setup and configure cloud services for your application. We can even support and maintain your infrastructure for you.</p>
            <ul>
                <li>Load balancers to effectively route your traffic to different servers as your application scales</li>
                <li>Secure, scalable and powerful file storage, so your user uploads are kept off your application servers</li>
                <li>Powerful database engines, that your application servers can leverage to store your data</li>
                <li>Auto-scaling configurations so your infrastructure grows and shrinks based on demand</li>
                <li>Automated SSL certificates, keeping traffic to your application secure</li>
                <li>Backups, monitoring and alerting to keep everything running smoothly</li>
                <li>Caching services to store user session data, and other temporary data, speeding up your application</li>
                <li>Authentication services to grant your users permission to access certain cloud resources</li>
                <li>Scalable queues to store and process background tasks, such as emails, avatar resizing, report generation, etc</li>
                <li>Fast notification systems to allow other applications and services to be notified when something happens</li>
            </ul>
            <p>We also work with serverless technologies such as Lambda and API Gateway, and can use these technologies to <a href="/services/serverless/voice">develop Voice applications</a></p>
        </PageContainer>
        
        <BackgroundContainer header={props.data.footer}>
            <TitleContainer>
                <FooterTitle>Need some AWS consultancy?</FooterTitle>

                <Link to="/contact" className="btn btn-lg mt-3 btn-primary">Get in touch</Link>
            </TitleContainer>
        </BackgroundContainer>
    </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "api-development.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    footer: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
